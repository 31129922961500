exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-forbidden-tsx": () => import("./../../../src/pages/forbidden.tsx" /* webpackChunkName: "component---src-pages-forbidden-tsx" */),
  "component---src-pages-index-old-tsx": () => import("./../../../src/pages/index-old.tsx" /* webpackChunkName: "component---src-pages-index-old-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-path-jsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__path}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-path-jsx" */),
  "component---src-pages-notfound-tsx": () => import("./../../../src/pages/notfound.tsx" /* webpackChunkName: "component---src-pages-notfound-tsx" */)
}

